<template>
  <MainLayout>
    <template v-slot:navbar-title>Добавить</template>
    <CreateFormLayout>
      <template v-slot:title__text>
        Выберите воспитателя
      </template>
      <template v-slot:title-flex-right>
        <div></div>
      </template>
      <template>
        <!--    Данные компании    -->
        <div>
          <FormInputBlock>
            <ValidationAutocompleteField
              :search-function="manageressSearch"
              label="Воспитатель"
              v-model="teachers"
              validate-name="Воспитатель"
            />
          </FormInputBlock>
        </div>
        <!--    Данные компании    -->
      </template>
      <template v-slot:btns="{ handleSubmit }">
        <a @click="$router.go(-1)" class="btn btn_color_white">
          Отмена
        </a>
        <v-btn
          type="submit"
          @click="handleSubmit(createManageress)"
          class="btn btn-green"
        >
          Сохранить
        </v-btn>
      </template>
    </CreateFormLayout>
    <v-overlay :value="loading" z-index="9999">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </MainLayout>
</template>

<script>
import MainLayout from "@/components/layouts/MainLayout";
import CreateFormLayout from "@/components/layouts/CreateFormLayout";
import FormInputBlock from "@/components/createFormBlocks/FormInputBlock";
import ValidationAutocompleteField from "@/components/ValidationField/ValidationAutocompleteField";
// import { getUsersRequest } from "@/helpers/api/user";
import { getPersonnelKindergartensRequest } from "@/helpers/api/kindergartens";
import {
  actionMessage,
  successMessageDictionary
} from "@/helpers/dictionariesHelper/successMessages";
export default {
  name: "ManageressCreateFormPage",
  components: {
    ValidationAutocompleteField,
    FormInputBlock,
    CreateFormLayout,
    MainLayout
  },
  props: {
    kindergartenId: {
      type: [String, Number]
    }
  },
  data() {
    return {
      manageressSearch: async value => {
        const filterOrgId = this.kindergartenId || this.currentOgr;
        return (
          await getPersonnelKindergartensRequest({
            id: filterOrgId,
            query: {
              search: value
            }
          })
        ).data.results
          .filter(el => el.position == 20)
          .map(el => ({
            text: `${el.phone_number}, ${el.additional_fields[0].value}`,
            value: el.phone_number
          }));
      },
      loading: false
    };
  },

  computed: {
    currentOgr() {
      return this.$store.getters.getCurrentOrganization;
    },
    teachers: {
      get() {
        return this.$store.getters.getManageressCreateForm.teachers;
      },
      set(newValue) {
        this.$store
          .dispatch("getTeacherId", { teacher: newValue })
          .then(teacherId => {
            this.$store.commit("setManageressCreateForm", {
              fieldName: "teachers",
              value: teacherId
            });
          });
      }
    }
  },

  mounted() {
    this.$store.commit("setManageressCreateForm", {
      fieldName: "childGroupId",
      value: this.$route.params.childrenGroupsId
    });
  },
  beforeDestroy() {
    this.$store.commit("clearCreateForm", {
      fieldName: "manageressCreateForm"
    });
  },
  methods: {
    createManageress() {
      this.loading = true;
      this.$store
        .dispatch("createManageress")
        .then(() => {
          this.$store.dispatch(
            "successNotification",
            actionMessage.add(successMessageDictionary.personnel)
          );
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
};
</script>

<style scoped></style>
